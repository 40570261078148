<template>
    <div class="monitor-water-container">
        <div class="search-section">
            <div class="search-item">
                <span class="search-title">船舶编码：</span>
                <Input v-model="searchShopId" placeholder="请输入船舶编码" style="width: 150px"></Input>
            </div>
            <!-- <div class="search-item">
        <span class="search-title">船舶ID：</span>
        <Input
          v-model="value"
          placeholder="请输入船舶ID"
          style="width: 150px"
        ></Input>
      </div> -->
            <div class="search-item">
                <span class="search-title">时间段：</span>
                <Date-picker format="yyyy-MM-dd HH:mm:ss" v-model="searchDateArr" type="datetimerange" placeholder="选择日期"
                    style="width: 250px"></Date-picker>
            </div>
            <div class="search-item">
                <span class="search-title">是否超标：</span>
                <Select v-model="searchIsOver" style="width:200px">
                    <Option v-for="item in isOverArr" :value="item.value" :key="item.label">{{ item.label }}</Option>
                </Select>
            </div>
            <Button @click="searchHandler" style="margin-left: 20px" type="success" class="btn">搜索</Button>
            <Button @click="resetHandler" style="margin-left: 20px" type="default" class="btn">重置</Button>
            <Button @click="exportExcelHandler" style="margin-left: 20px" type="primary" class="btn">导出</Button>
        </div>
        <Table :loading="loading" :data="shipGasList" :columns="columns"></Table>
        <Page @on-change="pageChange" @on-page-size-change="pageSizeChange" :total="total" size="small" show-total
            show-elevator show-sizer style="margin-top: 20px"></Page>
        <div v-if="detailModal" class="mask">
            <div class="detail-content">
                <div class="detail-header">
                    <p class="detail-header-title">通行记录</p>
                </div>
                <div class="video-section">
                    <template v-if="currentVideoDetailObj.isVideo">
                        <video ref="myVideo" :src="currentVideoDetailObj.url" class="video-player"></video>
                    </template>
                    <template v-else>
                        <img class="video-img" :src="currentVideoDetailObj.url" alt="" />
                    </template>
                </div>
                <div class="img-list">
                    <div @click="videoDetailHandler(index)" v-for="(item, index) in videoDetailList" :key="index"
                        class="img-list-item">
                        <img class="video-img" :src="item.smallUrl" alt="">
                    </div>
                </div>
                <div class="btn-section">
                    <Button @click="closeModalHandler" style="margin-left: 20px" type="primary" class="btn">关闭</Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getShopGasMonitorList, getVideoDetailInfo, exportGasInfoList } from '@/utils/api';
import { getDateByDatePicker, customPrint, throttle, antiShake } from '@/utils/util';
import { exportExcel } from '@/utils/export';

export default {
    data() {
        return {
            value: '',
            shipCode: '',
            detailModal: false,
            shipGasList: [
            ],
            loading: false,
            total: 0,
            columns: [ //num  序号 shipName 船舶名称 shipid 船舶编码 gasCode 尾气编码 gasName 尾气名称 batchNo 批次号 isOver 是否超标 gasValue 检测值 gasDate 监测时间
                {
                    title: "序号",
                    key: "num",
                    width: 70,
                },
                {
                    title: "船舶名称",
                    key: "shipName",
                    width: 200,
                },
                {
                    title: "船舶编码",
                    key: "shipid",
                    width: 200
                },
                {
                    title: "尾气编码",
                    key: "gasCode",
                    width: 200,
                },
                {
                    title: "尾气名称",
                    key: "gasName",
                    minWidth: 150,
                },
                {
                    title: "检测值",
                    key: "gasValue",
                    width: 150,
                },
                {
                    title: "是否超标",
                    key: "isOver",
                    width: 150,
                    render: (h, params) => {
                        return h(
                            "div",
                            { class: params.row.isExceed ? "over" : "normal" },
                            params.row.isExceed ? '是' : '否'
                        );
                    },
                },
                {
                    title: "监测时间",
                    key: "gasDate",
                    minWidth: 200,
                }
            ],
            isOverArr: [
                {
                    value: 0,
                    label: '否'
                },
                {
                    value: 1,
                    label: '是'
                }
            ],
            pageSize: 10,
            pageNum: 1,
            searchShopId: '',
            searchDateArr: [],
            searchIsOver: '',
            videoDetailList: [],
            currentVideoDetailObj: {},
            excelUrl: ''
        };
    },
    created() {
        this.getShopGasMonitorListAction();
    },
    methods: {
        videoDetailHandler(index) {
            let obj = this.videoDetailList[index]
            this.currentVideoDetailObj = obj;
            if (obj.isVideo) {
                setTimeout(() => {
                    this.$refs.myVideo.play();
                }, 500);
            }
        },
        exportExcelHandler: throttle(function () {
            const that = this;
            that.$Spin.show();
            that.exportGasInfoListAction();
        }, 1000),
        searchHandler() {
            customPrint('搜索时间数组:' + JSON.stringify(this.searchDateArr));
            customPrint('是否超标:' + JSON.stringify(this.searchIsOver));
            this.pageNum = 1;
            this.getShopGasMonitorListAction();
        },
        resetHandler() {
            this.pageSize = 10;
            this.pageNum = 1;
            this.searchShopId = '';
            this.searchDateArr = [];
            this.searchIsOver = '';
            this.getShopGasMonitorListAction();
        },
        pageChange(num) {
            this.pageNum = num;
            this.getShopGasMonitorListAction();
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getShopGasMonitorListAction();
        },
        testHandler(param) {
            customPrint("传递的参数:" + JSON.stringify(param));
        },
        breakLawImgHandler(obj) {
            customPrint("我点击了图片:" + JSON.stringify(obj));
            this.getShipVideoDetail(obj);
        },
        ok() { },
        cancel() { },
        closeModalHandler() {
            this.detailModal = false;
        },
        exportGasInfoListAction() {
            let params = {
                gasMonitorDTO: {}
            };
            if (this.searchShopId) {
                params.gasMonitorDTO.shipid = this.searchShopId;
            }
            if (this.searchDateArr.length && this.searchDateArr[0] != '') {
                params.gasMonitorDTO.startTime = getDateByDatePicker(this.searchDateArr[0]);
                params.gasMonitorDTO.endTime = getDateByDatePicker(this.searchDateArr[1]);
            }
            if (this.searchIsOver != '') {
                params.gasMonitorDTO.isExceed = parseInt(this.searchIsOver);
            }
            exportGasInfoList(params).then(res => {
                this.$Spin.hide();
                customPrint('导出气体信息回调:' + JSON.stringify(res));
                if (res.code == 0) {
                    this.excelUrl = res.data;
                    var downloadLink = document.createElement('a')
                    downloadLink.style.display = 'none' // 使其隐藏
                    downloadLink.href = this.excelUrl
                    downloadLink.download = ''
                    downloadLink.click()
                    // document.body.removeChild(downloadLink);
                } else { }
            })
        },
        getShopGasMonitorListAction() {
            let params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                gasMonitorDTO: {}
            };
            if (this.searchShopId) {
                params.gasMonitorDTO.shipid = this.searchShopId;
            }
            if (this.searchDateArr.length && this.searchDateArr[0] != '') {
                params.gasMonitorDTO.startTime = getDateByDatePicker(this.searchDateArr[0]);
                params.gasMonitorDTO.endTime = getDateByDatePicker(this.searchDateArr[1]);
            }
            if (this.searchIsOver != '') {
                params.gasMonitorDTO.isExceed = parseInt(this.searchIsOver);
            }
            customPrint('请求参数:' + JSON.stringify(params));
            getShopGasMonitorList(params).then(res => {
                customPrint('船舶气体检测列表:' + JSON.stringify(res));
                if (res.code == 0) {
                    this.total = res.data.total;
                    let list = res.data.list;
                    list.forEach((item, index) => {
                        item.num = index + 1;
                        // item.gasDate = timeFormatSeconds(item.gasDate);
                    })
                    this.shipGasList = list;
                } else { }
            })
        },
        getShipVideoDetail(obj) {
            let params = {
                mmsi: obj.shipid,
                batchNo: obj.batchNo
            }
            getVideoDetailInfo(params).then(res => {
                customPrint('视频图片详情:' + JSON.stringify(res));
                if (res.code == 0) {
                    let list = res.data;
                    customPrint('数组回调:' + JSON.stringify(list));
                    if (list.length) {
                        list.forEach(item => {
                            item.isVideo = false;
                            if (item.url.endsWith('.mp4')) {
                                item.isVideo = true;
                            }
                        })
                        this.videoDetailList = list;
                        this.currentVideoDetailObj = list[0];
                        this.detailModal = true;
                    }
                } else { }
            })
        }
    },
};
</script>

<style lang="scss">
.monitor-water-container {
    height: 100%;
    padding: 30px;
    text-align: center;
    overflow-y: auto;

    .search-section {
        display: flex;
        justify-content: flex-start;
        padding-bottom: 20px;

        .search-item {
            margin-left: 20px;

            &:first-child {
                margin-left: 0;
            }
        }

        .search-title {
            font-size: 16px;
            color: #fff;
        }
    }

    .over {
        color: red;
        font-weight: 600;
    }

    .ship-code {
        color: red;
        font-weight: 600;
    }

    .normal {
        color: green;
        font-weight: 600;
    }

    .mask {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba($color: #000000, $alpha: 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;

        .detail-content {
            background-color: #fff;
            border-radius: 10px;
            padding: 0 10px;

            .detail-header {
                padding: 20px 0;

                .detail-header-title {
                    font-size: 18px;
                }
            }

            .btn-section {
                padding: 20px 0;
                display: flex;
                justify-content: flex-end;
                padding-right: 20px;
            }

            .video-section {
                width: 600px;
                height: 400px;
                background-color: #eee;

                .video-img {
                    width: 100%;
                    height: 100%;
                }
            }

            .img-list {
                width: 600px;
                height: 150px;
                display: flex;
                padding-top: 10px;

                .img-list-item {
                    width: 300px;
                    height: 150px;
                    background-color: #eee;
                    margin-left: 10px;
                    cursor: pointer;

                    .video-img {
                        width: 100%;
                        height: 100%;
                    }

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}
</style>